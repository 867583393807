<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select type="text" hide-details filled label="Область" v-model="cityRegion" :items="regionSelect"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="cityRegion ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
          <v-col cols="12">
            <v-select type="text" hide-details filled label="Район" v-model="cityDistrict" :items="districtSelectFilter"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <AddressElementSelect v-model="amalgamated_hromada_id"
                                  label="Об’єднана громада" filled use_parent_id
                                  :parent_id="cityRegion" :req="false"
                                  select_type="amalgamated_hromada"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-select :items="cityTypes" hide-details filled label="Тип" v-model="cityType"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="cityType ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="8" md="9">
            <v-text-field type="text" hide-details filled label="Населений пункт" v-model="cityName"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="cityName ? '' : 'req-star'"
                          color="grey"
                          @change="onCityChange"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" hide-details filled label="Код КАТОТТГ" v-model="code_katottg" color="grey"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="text" hide-details filled label="Код області" v-model="regionCode" color="grey"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="text" hide-details filled label="Код району" v-model="districtCode" color="grey"/>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="text" hide-details filled label="Код нас. пункту" v-model="cityCode" color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createCity" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {cityTypes_select, regionSelect, districtSelect} from "@/utils/icons";
import {CREATE_CITY, DELETE_CITY, UPDATE_CITY} from "@/store/actions/city";
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'city_modal_delete'

export default {
  name: "HWP_Modal_City",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      regionSelect,
      districtSelect,
      cityTypes: cityTypes_select,
      cityId: this.item.id,
      cityType: this.item.city_type,
      cityName: this.item.name || '',
      cityCode: this.item.code || null,
      regionCode: this.item.region_code || null,
      districtCode: this.item.district_code || null,
      cityRegion: this.item.region || '',
      cityDistrict: this.item.district || '',
      amalgamated_hromada_id: this.item.amalgamated_hromada_id || null,
      code_katottg: this.item.code_katottg || null
    }
  },
  methods: {
    onCityChange(payload) {
      this.$nextTick(() => {
        if (payload === 'Тернопіль') {
          this.regionCode = 20
          this.cityCode = 99
          this.districtCode = null
          this.code_katottg = 'UA61040490010069060'
        }
      })
    },
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.cityId = this.item.id
      this.cityType = this.item.city_type
      this.cityName = this.item.name
      this.cityCode = this.item.code
      this.cityRegion = this.item.region
      this.regionCode = this.item.region_code
      this.districtCode = this.item.district_code
      this.cityDistrict = this.item.district
      this.amalgamated_hromada_id = this.item.amalgamated_hromada_id
      this.code_katottg = this.item.code_katottg

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення населеного пункту ${this.cityName}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createCity() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Область, Тип та Назва населеного пункту - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        name: this.cityName,
        code: this.cityCode,
        region: this.cityRegion,
        city_type: this.cityType,
        region_code: this.regionCode,
        district_code: this.districtCode,
        district: this.cityDistrict,
        amalgamated_hromada_id: this.amalgamated_hromada_id,
        code_katottg: this.code_katottg
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_CITY, payload)
            .then(el => {
              if (el) {
                this.closeModal({fetch: true, emit: true})
              }
            })
      } else {
        payload.id = this.cityId
        this.$store.dispatch(UPDATE_CITY, payload)
            .then(el => {
              if (el) {
                this.closeModal({fetch: true, emit: true})
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.cityId = payload.id
              this.cityType = payload.city_type
              this.cityName = payload.name
              this.cityCode = payload.code
              this.cityRegion = payload.region
              this.regionCode = payload.region_code
              this.districtCode = payload.district_code
              this.cityDistrict = payload.district
              this.amalgamated_hromada_id = payload.amalgamated_hromada_id
              this.code_katottg = payload.code_katottg
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_CITY, this.cityId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal({fetch: true, emit: true})
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    districtSelectFilter() {
      if (this.cityRegion) {
        return this.districtSelect.filter(i => i.region === this.cityRegion)
      } else {
        return []
      }
    }
  }
}
</script>
